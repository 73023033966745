<template>
  <div class="home">
    <div class="content-wrap">

      <h1>Unsere Standorte</h1>
      <p>Im Folgenden finden Sie eine Liste mit allen Bürostandorten und allen Abhol- und Rückgabestellen, die wir bedienen. Sie können auch auf der Karte überprüfen, damit Sie uns leicht finden können. Wenn Sie Hilfe benötigen oder wenn Sie ein Fahrzeug benötigen, dass an Ihrer Tür geliefert wird, kontaktieren Sie uns einfach.</p>

      <div class="row row-gutter-20 locations-page" style="margin-top:40px">
        <div v-for="location in locations" v-bind:key="location.id" class="col-6">
          <router-link :to="'/locations/'+location.id" class="home-location">
            <div class="location-img">
              <img v-if="location.image" :src="location.image" width="100%" height="150">
            </div>
            <div class="location-body">
              <h3>{{ location.name }}</h3>
              <p><span class="material-icons">gps_fixed</span><span>{{ location.street_name }} {{ location.street_number }}</span></p>
              <p><span>{{ location.zip_code }} {{ location.city }}</span></p>
              <p><span class="material-icons">phone</span><span>{{ location.contact_phone }}</span></p>
              <p><span class="material-icons">mail</span><span>{{ location.contact_email }}</span></p>
            </div>
          </router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'locations',
  data () {
    return {
      locations: [],
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/locations', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.locations = response.data.locations;
      })
      .catch(error => {
        console.log(error);
      })

    }
  },
  mounted () {
    this.get_data();
  }
}
</script>
